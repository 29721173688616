<template>
    <mavon-editor class="custom-editor" ref="md" v-bind="$attrs" v-on="$listeners" code-style="atom-one-dark">
        <!-- 引用视频链接的自定义按钮 -->
        <template v-slot:left-toolbar-after>
            <!--点击按钮触发的事件是打开表单对话框-->
            <el-button type="text" @click="handleImgClick" aria-hidden="true" class="op-icon fa" title="插入图片">
                <i class="el-icon-picture-outline" />
                <input ref="imgFile" type="file" style="display:none;" accept="image/*" @change="handleImgChange" />
            </el-button>
            <el-button type="text" @click="handleClick" aria-hidden="true" class="op-icon fa" title="插入视频资源">
                <i class="el-icon-video-camera-solid" />
                <input ref="file" type="file" style="display:none;" accept="video/*" @change="handleChange" />
            </el-button>
        </template>
    </mavon-editor>
</template>
<script>
import { baseUrl } from '@/utils/config'
export default {
    computed: {
        isVideo() {
            const ext = this.url ? this.url.split("?")[0].split('.').pop() : ""
            return ['mp4', 'avi', 'mkv', 'wmv', 'flv', 'webm'].includes(ext)
        },
        realUrl() {
            console.log(this.url, "xxxx")
            if (!this.url) {
                return ''
            }
            return this.url.startsWith('http') ? this.url : baseUrl + this.url
        }
    },
    methods: {
        handleImgClick() {
            this.$refs.imgFile.click()
        },
        handleImgChange(e) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            this.$request.post("file/upload", formData).then((res) => {
                if (res.code === 200) {
                    let linkFrame = `<img width=100% src="${baseUrl + res.data}" />`;
                    
                    // 获取文本域中当前光标起始位置、结束位置以及滚动条位置（滚动条位置我认为没有必要，如有需要可以自己取消注释）
                    let textarea = document.getElementsByClassName("auto-textarea-input")[0];
                    let posStart = textarea.selectionStart;
                    let posEnd = textarea.selectionEnd;
                    // let posScroll = document.getElementsByClassName("v-note-edit")[0].scrollTop;
                    // 获取文本域中未选中的的前半部分和后半部分，以被选中内容起始和结束位置做分割点
                    let subStart = this.$refs.md.d_value.substring(0, posStart);
                    let subEnd = this.$refs.md.d_value.substring(posEnd, this.$refs.md.d_value.length);
                    // 拼接并替换文本域内容
                    this.$refs.md.d_value = subStart + '\n' + linkFrame + '\n' + subEnd + '\n';
                }
            })
            e.target.value = ""
        },
        handleClick() {
            this.$refs.file.click()
        },
        handleChange(e) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            this.$request.post("file/upload", formData).then((res) => {
                if (res.code === 200) {
                    let linkFrame = `<video width=100% src="${baseUrl + res.data}" controls></video>`;
                    
                    // 获取文本域中当前光标起始位置、结束位置以及滚动条位置（滚动条位置我认为没有必要，如有需要可以自己取消注释）
                    let textarea = document.getElementsByClassName("auto-textarea-input")[0];
                    let posStart = textarea.selectionStart;
                    let posEnd = textarea.selectionEnd;
                    // let posScroll = document.getElementsByClassName("v-note-edit")[0].scrollTop;
                    // 获取文本域中未选中的的前半部分和后半部分，以被选中内容起始和结束位置做分割点
                    let subStart = this.$refs.md.d_value.substring(0, posStart);
                    let subEnd = this.$refs.md.d_value.substring(posEnd, this.$refs.md.d_value.length);
                    // 拼接并替换文本域内容
                    this.$refs.md.d_value = subStart + '\n' + linkFrame + '\n' + subEnd + '\n';
                }
            })
            e.target.value = ""
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-editor {
    ::v-deep {
        .op-icon.fa.fa-mavon-picture-o {
            display: none;
        }
    }
}

.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 400px;

    input {
        display: none;
    }
}

.avatar-uploader:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>