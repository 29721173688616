<template>
    <div class="cover-wrap">
        <video class="avatar" v-if="url && isVideo" :src="realUrl"></video>
        <img class="avatar" v-if="url && !isVideo" :src="realUrl" />
    </div>
</template>
<script>
import { baseUrl } from '@/utils/config'
export default {
    props: {
        url: String
    },
    computed: {
        isVideo() {
            const ext = this.url ? this.url.split("?")[0].split('.').pop() : ""
            return ['mp4', 'avi', 'mkv', 'wmv', 'flv', 'webm'].includes(ext)
        },
        realUrl() {
            console.log(this.url, "xxxx")
            if (!this.url) {
                return ''
            }
            return this.url.startsWith('http') ? this.url : baseUrl + this.url
        }
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
.avatar {
    width: 100px;
    height: 50px;
}
</style>