<template>
  <div class="table-page">
    <el-card class="search">
      <el-form ref="searchForm" :model="searchForm" inline>
        <el-form-item label="文章标题" prop="title">
          <el-input v-model.trim="searchForm.title" placeholder="文章标题" class="input" clearable />
        </el-form-item>
        <el-form-item label="文章标签" prop="tags">
          <el-select v-model="searchForm.tags" multiple placeholder="文章标签" class="input" clearable>
            <el-option v-for="tag in tagsList" :key="tag.id" :value="tag.id" :label="tag.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指定为banner" prop="isbanner">
          <el-select v-model="searchForm.isbanner" class="input" clearable>
            <el-option v-for="item in flagList" :key="item.value" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="search">搜索</el-button>
          <el-button size="medium" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="content">
      <div class="btn-wrap">
        <el-button size="mini" type="primary" @click="handleAdd">新增文章</el-button>
      </div>
      <el-table :data="list" element-loading-text="Loading" border fit highlight-current-row>
        <el-table-column label="标题" align="center">
          <template slot-scope="scope">
            {{ scope.row.title }}
          </template>
        </el-table-column>
        <el-table-column label="封面" align="center">
          <template slot-scope="scope">
            <Cover :url="scope.row.cover" />
          </template>
        </el-table-column>
        <el-table-column label="是否banner" align="center">
          <template slot-scope="scope">
            {{
              scope.row.isbanner ? flagMap[scope.row.isbanner] : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column label="标签" align="center">
          <template slot-scope="scope">
            {{ scope.row.tagsList.map(t => t.name).join("、") }}
          </template>
        </el-table-column>
        <el-table-column label="排序" align="center">
          <template slot-scope="scope">
            {{ scope.row.sort }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="240">
          <template slot-scope="scope">
            <el-button v-if="scope.row.state == 0" size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.state == 0" size="mini" type="primary" @click="handlePublish(scope.row, 1)">发布</el-button>
            <el-button v-if="scope.row.state == 1" size="mini" type="danger" @click="handlePublish(scope.row, 0)">取消发布</el-button>
            <el-button size="mini" type="danger" @click="handleDel(scope.row.id, scope.row.title)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background :page-size.sync="pageInfo.pageSize" :current-page.sync="pageInfo.pageNo"
          layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-card>

    <el-dialog :title="isEdit ? '编辑' + compLabel : '新增' + compLabel" :visible.sync="showDialog" width="80%">
      <el-form ref="submitForm" :model="submitForm" :rules="rules" label-width="100px">
        <el-form-item label="标题" prop="title">
          <el-input v-model.trim="submitForm.title" class="input" clearable />
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model.trim="submitForm.desc" class="input" clearable />
        </el-form-item>
        <el-form-item label="封面" prop="cover">
          <UploadCover v-model="submitForm.cover" />
        </el-form-item>
        <el-form-item label="设置为轮播" prop="isbanner">
          <el-select v-model="submitForm.isbanner" class="input">
            <el-option v-for="item in flagList" :key="item.value" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章标签" prop="tags">
          <el-select v-model="submitForm.tags" multiple placeholder="文章标签" class="input" clearable>
            <el-option v-for="tag in tagsList" :key="tag.id" :value="tag.id" :label="tag.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model.trim="submitForm.sort" class="input" clearable />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <MyMavonEditor v-model="submitForm.content" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="sure('submitForm', 0)">
            暂存
          </el-button>
          <el-button type="primary" size="medium" @click="sure('submitForm', 1)">
            发布
          </el-button>
          <el-button size="medium" @click="cancel('submitForm')">
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex"
import Cover from "@/components/Cover"
import UploadCover from "@/components/UploadCover"
import MyMavonEditor from "@/components/MyMavonEditor"
import { flagList, flagMap } from "@/utils/dict"

const compLabel = "文章"
const searchForm = {
  title: "",
  tags: [],
  isbanner: ""
}
const submitForm = {
  title: '',
  desc: '',
  sort: '',
  isbanner: "0",
  tags: [],
  content: ''
}

export default {
  name: 'Article',
  components: {
    Cover,
    UploadCover,
    MyMavonEditor
  },
  data() {
    return {
      flagList,
      flagMap,
      compLabel,
      list: [],
      searchForm: { ...searchForm },
      total: 0,
      pageInfo: {
        pageNo: 1,
        pageSize: 10
      },
      submitForm: { ...submitForm },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        tags: [{ required: true, message: '请选择标签', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入文章内容', trigger: 'blur' },
        ],
      },
      showDialog: false,
      isEdit: false,
      tagsList: []
    }
  },
  computed: {
    ...mapState(["admin"])
  },
  created() {
    this.getTagsList()
    this.getList()
  },
  methods: {
    getTagsList() {
      this.$request.post("/tags/listAll").then((res) => {
        if (res.code == 200) {
          this.tagsList = res.data
        }
      })
    },
    getList() {
      this.$request.post("/article/list", { ...this.searchForm, ...this.pageInfo }).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    search() {
      this.pageInfo.pageNo = 1;
      this.getList();
    },
    reset() {
      this.searchForm = { ...searchForm }
      this.search()
    },
    handleSizeChange() {
      this.pageInfo.pageNo = 1
      this.getList();
    },
    handleCurrentChange() {
      this.getList()
    },
    handleAdd() {
      this.showDialog = true;
      this.submitForm = { ...submitForm }
      this.isEdit = false
    },
    handleEdit(row) {
      this.showDialog = true;
      this.submitForm = { ...row, tags: row.tags.split(",").map(t => Number(t)) }
      this.isEdit = true
    },
    handleDel(id, name) {
      this.$confirm(`是否删除${compLabel}：${name} ？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.post("/article/del", { ids: [id] }).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.search()
            }
          })
        })
        .catch(() => { })
    },
    handlePublish(row, state) {
      this.$request.post("/article/edit", {
        ...row,
        state,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.getList();
        }
      })
    },
    sure(formName, state) {
      console.log(this.admin, "xxxx")
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const apiUrl = this.isEdit ? "/article/edit" : "/article/add"
          this.$request.post(apiUrl, {
            ...this.submitForm,
            state,
            tags: this.submitForm.tags.join(","),
            creator_id: this.admin.id,
            creator_name: this.admin.name
          }).then((res) => {
            if (res.code == 200) {
              this.showDialog = false;
              this.$message.success(this.isEdit ? '编辑成功' : '新增成功')
              !this.isEdit ? this.search() : this.getList();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.showDialog = false;
    }
  },
}
</script>
<style lang="scss" scoped>
.input {
  width: 100%;
}
</style>
