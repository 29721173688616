<template>
    <div class="avatar-uploader" @click="handleClick">
        <template v-if="url">
            <video class="avatar" v-if="isVideo" :src="realUrl"></video>
            <img class="avatar" v-if="!isVideo" :src="realUrl" />
        </template>
        <i v-else class="el-icon-plus avatar-uploader-icon" />
        <input ref="file" type="file" accept="image/*,video/*" @change="handleChange" />
    </div>
</template>
<script>
import { baseUrl } from '@/utils/config'
export default {
    model: {
        prop: "url",
        event: "change"
    },
    props: {
        url: String
    },
    computed: {
        isVideo() {
            const ext = this.url ? this.url.split("?")[0].split('.').pop() : ""
            return ['mp4', 'avi', 'mkv', 'wmv', 'flv', 'webm'].includes(ext)
        },
        realUrl() {
            console.log(this.url, "xxxx")
            if (!this.url) {
                return ''
            }
            return this.url.startsWith('http') ? this.url : baseUrl + this.url
        }
    },
    methods: {
        handleClick() {
            this.$refs.file.click()
        },
        handleChange(e) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            this.$request.post("/file/upload", formData).then((res) => {
                if (res.code === 200) {
                    this.$emit("change", res.data)
                }
            })
            e.target.value = ""
        }
    }
}
</script>
<style lang="scss" scoped>
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 400px;

    input {
        display: none;
    }
}

.avatar-uploader:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    max-width: 100%;
    max-height: 100%;
    display: block;
}
</style>